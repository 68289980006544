import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType, getDaDataTokenType } from './NavMenu';

const initialState = {
    dadataToken: ""
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType })
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === getDaDataTokenType) {
        return {
            ...state,
            dadataToken: action.token
        };        
    }

    return state;
};
