import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Users from './Users';
import * as UserEdit from './UserEdit';
import * as NavMenu from './NavMenu';
import * as Login from './Login';
import * as Settings from './Settings';
import * as ClientEdit from './ClientEdit';
import * as ClientReg from './ClientReg';
import * as ClientView from './ClientView';
import * as UserView from './UserView';
import * as Clients from './Clients';
import * as PhoneVerify from './PhoneVerify';
import * as ClientImport from './ClientImport';
import * as Analytics from './Analytics';
import * as AgentClients from './AgentClients';

export default function configureStore (history, initialState) {
  const reducers = {
      users: Users.reducer,
      clientImport: ClientImport.reducer,
      userEdit: UserEdit.reducer,
      navMenu: NavMenu.reducer,
      login: Login.reducer,
      clientEdit: ClientEdit.reducer,
      clientReg: ClientReg.reducer,
      clientView: ClientView.reducer,
      userView: UserView.reducer,
      clients: Clients.reducer,
      phoneVerify: PhoneVerify.reducer,
      settings: Settings.reducer,
      agentClients: AgentClients.reducer,
      analytics: Analytics.reducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
