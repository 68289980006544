import HttpUtil from '../util/HttpUtil';
import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const startFetchSettingsType = 'START_FETCH_SETTINGS';
const fetchSettingsType = 'FETCH_SETTINGS';
const setFieldType = 'SET_SETTINGS_FIELD';
const startSaveType = 'START_SETTINGS_SAVE';
const saveType = 'SETTINGS_SAVE';

const initialState = {
    settings: {},
    isLoading: true,
    isSaving: false
};

export const actionCreators = {
    fetch: () => async (dispatch, getState) => {
        dispatch({ type: startFetchSettingsType });
        HttpUtil.fetchGet('api/Settings', null, true,
            data => {
                dispatch({ type: fetchSettingsType, settings: data });
            }
        );
    },
    setField: (field, value) => async (dispatch, getState) => {
        dispatch({ type: setFieldType, field, value });
    },
    saveSettings: () => async (dispatch, getState) => {
        dispatch({ type: showGlobalLoaderType });
        dispatch({ type: startSaveType });
        HttpUtil.fetchEdit('api/Settings', JSON.stringify(getState().settings.settings), "POST", false,
            data => {
                if (data.result === "OK") {
                    dispatch({ type: saveType });
                    dispatch({ type: showSnackBarType, message: "Настройки успешно сохранены!" });
                    dispatch({ type: hideGlobalLoaderType });
                }
            }
        )
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === startFetchSettingsType) {
        return {
            ...state,
            isLoading: true
        }
    }

    if (action.type === setFieldType) {
        return {
            ...state,
            settings: {
                ...state.settings,
                [action.field]: action.value
            }
        }
    }

    if (action.type === fetchSettingsType) {
        return {
            ...state,
            isLoading: false,
            settings: action.settings
        }
    }

    if (action.type === startSaveType) {
        return {
            ...state,
            isSaving: true
        }
    }

    if (action.type === saveType) {
        return {
            ...state,
            isSaving: false
        }
    }

    return state;
};
