import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

//export const deleteClientType = "DELETE_CLIENT";

const initialState = {
};

export const actionCreators = {
    showSnackBar: (message) => ({ type: showSnackBarType, message }),
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    //delete: (id) => ({ type: deleteClientType, id })
};

export const reducer = (state, action) => {
    state = state || initialState;

    return state;
};
