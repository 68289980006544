import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/NavMenu';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { withRouter } from 'react-router-dom';

import {
    Snackbar,
    LinearProgress
} from '@material-ui/core';

import { ADMIN_ROLE, SUPER_ADMIN_ROLE, EMPLOYER_ROLE, AGENT_ROLE, MANAGER_ROLE, MARKETOLOGIST_ROLE, OWNER_ROLE } from '../const/roles';
import * as Roles from '../const/roles';
import ym from 'react-yandex-metrika';
import { GetRole } from '../const/common';
import asyncComponent from './AsyncComponent';
import { Assessment } from '@material-ui/icons';
//import Teaching from './Teaching';

const AppBar = asyncComponent(() => import("@material-ui/core/AppBar"));
const Toolbar = asyncComponent(() => import("@material-ui/core/Toolbar"));
const Button = asyncComponent(() => import("@material-ui/core/Button"));
const Menu = asyncComponent(() => import("@material-ui/core/Menu"));
const List = asyncComponent(() => import("@material-ui/core/List"));
const ListItem = asyncComponent(() => import("@material-ui/core/ListItem"));
const ListItemText = asyncComponent(() => import("@material-ui/core/ListItemText"));
const ListItemIcon = asyncComponent(() => import("@material-ui/core/ListItemIcon"));
const Typography = asyncComponent(() => import("@material-ui/core/Typography"));
const IconButton = asyncComponent(() => import("@material-ui/core/IconButton"));
const Drawer = asyncComponent(() => import("@material-ui/core/Drawer"));
const Divider = asyncComponent(() => import("@material-ui/core/Divider"));
const Dialog = asyncComponent(() => import("@material-ui/core/Dialog"));
const DialogContent = asyncComponent(() => import("@material-ui/core/DialogContent"));
const DialogActions = asyncComponent(() => import("@material-ui/core/DialogActions"));
const DialogTitle = asyncComponent(() => import("@material-ui/core/DialogTitle"));
const DialogContentText = asyncComponent(() => import("@material-ui/core/DialogContentText"));


const MenuIcon = asyncComponent(() => import("@material-ui/icons/Menu"));
const AccountCircle = asyncComponent(() => import("@material-ui/icons/AccountCircle"));
const Contacts = asyncComponent(() => import("@material-ui/icons/Contacts"));
const HowToVote = asyncComponent(() => import("@material-ui/icons/HowToVote"));
const MonetizationOn = asyncComponent(() => import("@material-ui/icons/MonetizationOn"));
const Archive = asyncComponent(() => import("@material-ui/icons/Archive"));
const People = asyncComponent(() => import("@material-ui/icons/People"));
const ChevronLeft = asyncComponent(() => import("@material-ui/icons/ChevronLeft"));
const SettingsSharp = asyncComponent(() => import("@material-ui/icons/SettingsSharp"));
const CreditCard = asyncComponent(() => import("@material-ui/icons/CreditCard"));

class NavMenu extends Component {
    _menuButton = null;
    _notifButton = null;


    constructor(props, context) {
        super(props, context);
        this.props.checkLogin();
        this.state = {
            menuOpen: false,
            welcome: false,
            exitModal: false
        };
        this._setTitle = this._setTitle.bind(this);

        props.history.listen((location) => {
            ym('hit', location.pathname);
        });
    }

    componentWillMount() {
        var token = Cookies.get('accessToken')
        if (token !== undefined)
            this.props.fetch();
    }

    componentDidMount() {
        this._setTitle();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showLoginButton === true &&
            nextProps.showLoginButton === false &&
            this.props.location.pathname !== '/login' &&
            nextProps.location.pathname !== '/login' ) {
            if (GetRole() === null && this.props.location.pathname !== '/' && nextProps.location.pathname !== '/') {
                this.props.history.push('/');
            } else if (GetRole() !== null) {
                this.props.history.push('/login');
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this._setTitle();
        }
       /* if (this.props.user &&
            !this.props.user.teachingComplete &&
            this.props.user.roles[0] !== ADMIN_ROLE &&
            this.props.user.roles[0] !== SUPER_ADMIN_ROLE &&
            this.state.welcome === false
        ) {
            this.setState({ welcome: true });
        }  */     
    }

    _setTitle() {
        var title = "Анкетирование клиентов";
        let role = "";
        if (this.props.user) {
            role = this.props.user.roles[0];
            if (role === ADMIN_ROLE.value || role === SUPER_ADMIN_ROLE.value) {
                title = "Управление пользователями";
            } else if (role === Roles.MARKETOLOGIST_ROLE.value || role === Roles.OWNER_ROLE.value || role === Roles.MANAGER_ROLE.value) {
                title = "Список клиентов";
            } else if (role === Roles.EMPLOYER_ROLE.value || role === Roles.AGENT_ROLE.value) {
                title = "Поиск клиента";
            }
        } else {
            if (this.props.location.pathname.indexOf('/login') > -1) {
                title = 'Вход';
            }
            document.title = title + ' — Сервис лояльности';
            return;
        }

        if (typeof document !== 'undefined') {
            if (this.props.location.pathname.indexOf('/login') > -1) {
                this.props.history.push("/");
            } else if (this.props.location.pathname.indexOf('/registration') > -1 || this.props.location.pathname.indexOf('/complete') > -1) {
                title = 'Регистрация';
            }  else if (this.props.location.pathname.indexOf('/access_forbidden') > -1) {
                title = 'Доcтуп запрещен';
            } else if (this.props.location.pathname.indexOf('/users') > -1) {
                if (role === AGENT_ROLE.value || role === EMPLOYER_ROLE.value) {
                    this.props.history.push("/access_forbidden");
                }
                if (role === ADMIN_ROLE.value || role === SUPER_ADMIN_ROLE.value) {
                    title = 'Управление пользователями';
                } else {
                    title = 'Просмотр агентов';
                }
            } else if (this.props.location.pathname.indexOf('/viewuser') > -1) {
                title = 'Карточка агента';
            } else if (this.props.location.pathname.indexOf('/viewclient') > -1) {
                title = 'Карточка клиента';
            } else if (this.props.location.pathname.indexOf('/viewimport') > -1) {
                title = 'Карточка исторического клиента';
            } else if (this.props.location.pathname.indexOf('/user') > -1) {
                if (role !== ADMIN_ROLE.value && role !== SUPER_ADMIN_ROLE.value && role !== Roles.MANAGER_ROLE.value) {
                    this.props.history.push("/access_forbidden");
                }
                if (role === Roles.MANAGER_ROLE) {
                    title = "Агент";
                } else {
                    title = 'Пользователь';
                }
            } else if (this.props.location.pathname.indexOf('/settings') > -1) {
                if (role !== SUPER_ADMIN_ROLE.value) {
                    this.props.history.push("/access_forbidden");
                }
                title = 'Настройки системы';
            } else if (this.props.location.pathname.indexOf('/clients') > -1) {
                if (role === AGENT_ROLE.value || role === EMPLOYER_ROLE.value) {
                    this.props.history.push("/access_forbidden");
                }
                if (this.props.location.pathname.indexOf('/all') > -1) {
                    title = 'Просмотр клиентов';
                } else if (this.props.location.pathname.indexOf('/new') > -1) {
                    title = 'Управление заявками';
                } else {
                    title = 'Исторические клиенты из 1С';
                }
            } else if (this.props.location.pathname.indexOf('/client') > -1) {
                if (this.props.location.pathname.indexOf('/approve') > -1) {
                    title = 'Карточка заявки';
                } else {
                    if (role === EMPLOYER_ROLE.value || role === AGENT_ROLE.value) {
                        title = this.props.location.pathname.indexOf('/add') > -1 ? "Новый клиент" : "Карточка клиента";
                    } else {
                        title = "Клиент";
                    }
                }
            } else if (this.props.location.pathname.indexOf('/analytics') > -1) {
                if (role === EMPLOYER_ROLE.value || role === AGENT_ROLE.value) {
                    this.props.history.push("/access_forbidden");
                }
                title = "Клиентская аналитика";
            } else if (this.props.location.pathname.indexOf('/my_clients') > -1) {
                title = "Мои клиенты";
            }
            let docTitle = title + " — Сервис лояльности";
            document.title = docTitle;
        } else {
            title = 'Загрузка...';
        }
        return title;
    }

    render() {
        const { classes } = this.props;

        if (this.props.location.pathname.indexOf('/access_forbidden') > -1) {
            return <div style={{ height: "100%", display: "flex" }} className={classes.contentInner}>
                {this.props.children}
            </div>;
        }

        var drawer = typeof document !== 'undefined' && GetRole !== null ? (
            <Drawer
                variant='persistent'
                anchor='left'
                open={this.props.drawerOpen}
                classes={{
                    paper: classNames(classes.drawerPaper, {
                        [classes.drawerPaperShift]: this.props.drawerOpen
                    })
                }}
            >
                <div className={classes.drawerHeader}>
                    <div className={classes.flexDisp}>
                        <img alt='System Logo' src='/logo.svg' height={40} width={40} />
                        <Typography style={{ marginLeft: 8, fontSize: "1.1125rem" }} variant='title' color='inherit'>
                            Сервис лояльности
                        </Typography>
                    </div>
                    <IconButton onClick={() => this.props.hideDrawer()}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <List style={{ padding: 0 }}>
                    {
                        (this.props.user && this.props.user.roles[0] !== Roles.AGENT_ROLE.value && this.props.user.roles[0] !== Roles.EMPLOYER_ROLE.value) &&
                        <ListItem button onClick={() => this.props.history.push('/analytics')}>
                            <ListItemIcon>
                                <Assessment />
                            </ListItemIcon>
                            <ListItemText primary={"Аналитика"} />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] === EMPLOYER_ROLE.value) &&
                        <ListItem button onClick={() => this.props.history.push('/')}>
                            <ListItemIcon>
                                <CreditCard />
                            </ListItemIcon>
                            <ListItemText primary={"Оформление"} />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] === AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.history.push('/')}>
                            <ListItemIcon>
                                <CreditCard />
                            </ListItemIcon>
                            <ListItemText primary={"Новый клиент"} />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] === AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.history.push('/my_clients')}>
                            <ListItemIcon>
                                <Contacts />
                            </ListItemIcon>
                            <ListItemText primary={"Клиенты"} />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] !== EMPLOYER_ROLE.value && this.props.user.roles[0] !== AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.location.pathname !== "/users" && this.props.history.push('/users')}>
                            <ListItemIcon>
                                {this.props.user.roles[0] === ADMIN_ROLE.value || this.props.user.roles[0] === SUPER_ADMIN_ROLE.value ? <People /> : <MonetizationOn />}
                            </ListItemIcon>
                            <ListItemText primary={this.props.user.roles[0] === ADMIN_ROLE.value || this.props.user.roles[0] === SUPER_ADMIN_ROLE.value ? "Пользователи" : "Агенты"} />
                        </ListItem>
                    }
                    {
                        (this.props.user && (this.props.user.roles[0] === SUPER_ADMIN_ROLE.value)) &&
                        <ListItem button onClick={() => this.props.history.push('/settings')}>
                            <ListItemIcon>
                                <SettingsSharp />
                            </ListItemIcon>
                            <ListItemText primary='Настройки' />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] !== EMPLOYER_ROLE.value && this.props.user.roles[0] !== AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.location.pathname !== "/clients/all" && this.props.history.push('/clients/all')}>
                            <ListItemIcon>
                                <Contacts />
                            </ListItemIcon>
                            <ListItemText primary='Клиенты' />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] !== EMPLOYER_ROLE.value && this.props.user.roles[0] !== AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.location.pathname !== "/clients/new" && this.props.history.push('/clients/new')}>
                            <ListItemIcon>
                                <HowToVote />
                            </ListItemIcon>
                            <ListItemText primary='Анкеты' />
                        </ListItem>
                    }
                    {
                        (this.props.user && this.props.user.roles[0] !== EMPLOYER_ROLE.value && this.props.user.roles[0] !== AGENT_ROLE.value) &&
                        <ListItem button onClick={() => this.props.location.pathname !== "/clients/import" && this.props.history.push('/clients/import')}>
                            <ListItemIcon>
                                <Archive />
                            </ListItemIcon>
                            <ListItemText primary="Миграция" />
                        </ListItem>
                    }
                </List>
            </Drawer>
        ) : null;

        let role = "";
        if (this.props.user) {
            var k = Object.keys(Roles).find(k => Roles[k].value === this.props.user.roles[0]);
            role = Roles[k].label;
        }

        return <div className={classes.appFrame}>
            {
                this.props.globalLoading &&
                <LinearProgress color='secondary' className={classes.globalLoader} />
            }
            {
                this.props.showPreScreen &&
                <div className={classes.preScreen}>
                    <img alt='System Logo' src='/splash.svg' className={classes.preScreenImg} />
                </div>
            }
            {
                (this.props.showLoginButton && this.props.user !== null) &&
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: this.props.drawerOpen,
                        [classes.appBarShiftLeft]: this.props.drawerOpen
                    })}
                >
                    <Toolbar>
                        {  
                            this.props.user &&
                            <IconButton
                                className={classNames(classes.menuButton, this.props.drawerOpen && classes.hide)}
                                color='inherit'
                                aria-label='Menu'
                                onClick={this.props.openDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                        }
                        <Typography variant='title' color='inherit' className={classes.flex}>
                            {this._setTitle()}
                        </Typography>
                        <div className={classNames(classes.centerFlex, classes.white)}>
                            <IconButton
                                aria-owns={this.state.menuOpen ? 'menu-appbar' : null}
                                aria-haspopup='true'
                                buttonRef={c => this._menuButton = c}
                                onClick={() => this.setState({ menuOpen: true })}
                                color='inherit'
                            >
                                <AccountCircle className={classes.accountCircle} />
                            </IconButton>
                            <Menu
                                id='menu-appbar'
                                anchorEl={this._menuButton}
                                open={this.state.menuOpen}
                                classes={{ paper: classes.accountMenu }}
                                onClose={() => this.setState({ menuOpen: false })}
                            >
                                <div style={{ paddingTop: 8, paddingBottom: 16 }} className={classNames(classes.centerFlex, classes.topMenu)}>
                                    <AccountCircle color='disabled' className={classes.accountCircleLarge} />
                                    <div style={{ marginLeft: 16 }}>
                                        <Typography color='secondary'>{(GetRole() !== AGENT_ROLE.value ?  this.props.user.surname + ' ' : "") + this.props.user.name}</Typography>
                                        <Typography>
                                            {role}
                                        </Typography>
                                    </div>
                                </div>
                                <Divider />
                                <div className={classNames(classes.centerFlex, classes.flexBetween, classes.topMenuBottom)}>
                                    <Typography color='textSecondary'>{this.props.user.Email}</Typography>
                                    <Button
                                        onClick={() => {
                                            this.setState({ menuOpen: false, exitModal: true });
                                        }}
                                    >
                                        Выйти
                                    </Button>
                                </div>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
            }
            {
                (
                    this.props.user &&
                    this.props.showLoginButton &&
                    this.props.drawerOpen != null
                ) &&
                drawer
            }
            <div
                className={classNames(classes.content,
                    'navMenuMargin', {
                        [classes.contentMargin]: this.props.showLoginButton,
                        [classes.contentShift]: this.props.drawerOpen && this.props.location.pathname.indexOf('/login') === -1,
                        [classes.contentShiftLeft]: this.props.drawerOpen && this.props.location.pathname.indexOf('/login') === -1,
                        [classes.contentLeft]: this.props.showLoginButton,
                        [classes.centerFlex]: !this.props.showLoginButton
                    })}
            >
                <div className={classes.contentInner}>
                    {this.props.children}
                </div>
            </div>
            {
                this.props.showLoginButton &&
                <Dialog
                    open={this.state.exitModal !== false}
                    onClose={() => this.setState({ exitModal: false })}
                >
                    <DialogTitle>Выход</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Выйти из системы?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ exitModal: false })}>Отмена</Button>
                        <Button
                            onClick={() => {
                                this.props.history.push('/login');
                                this.props.logout();
                                this.setState({
                                    exitModal: false
                                });
                            }}
                        >
                            ОК
                    </Button>
                    </DialogActions>
                </Dialog>
            }
            {
                /*this.props.user && !this.props.user.teachingComplete &&
                <Teaching onClose={() => {
                        this.setState({ welcome: false });
                        this.props.completeTeaching();
                    }} open={this.state.welcome}
                />*/
            }
            <Snackbar
                open={this.props.snackBarMessage !== ''}
                message={this.props.snackBarMessage}
                autoHideDuration={4000}
                onClose={this.props.hideSnackBar}
            />
        </div>;
    }
}

const drawerWidth = 240;

const styles = theme => ({
    accountCircle: {
        fontSize: 30
    },
    accountCircleLarge: {
        fontSize: 40
    },
    accountMenu: {
        marginTop: 49,
        marginRight: 10,
        overflow: 'visible',
        '&:before': {
            position: 'absolute',
            right: 24,
            top: -16,
            borderColor: 'transparent transparent white',
            border: '8px solid',
            height: 0,
            width: 0,
            content: '" "'
        }
    },
    appBar: {
        paddingRight: "0 !important",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShiftLeft: {
        marginLeft: drawerWidth
    },
    appFrame: {
        zIndex: 1,
        overflow: 'hidden', /*auto*/
        position: 'relative',
        display: 'flex',
        width: '100%',
        /*height: '100%', /*IECSS*/
        minHeight: '100%'
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center'
    },
    colorTutorial: {
        color: '#08ad02'
    },
    colorCallUndesirable: {
        color: '#ffd230'
    },
    colorSendUndesirable: {
        color: 'rgb(225, 0, 80)',
    },
    content: {
        flexGrow: 1,
        width: 'calc(100% - 290px)',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
    },
    contentInner: {
        marginLeft: 'auto',
        marginRight: 'auto',
        ['@media (min-width: 1024px)']: {
            width: 960
        }
    },
    contentMargin: {
        marginTop: 64
    },
    contentLeft: {
        marginLeft: 0//-drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex'
    },
    contentShiftLeft: {
        marginLeft: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    drawerPaper: {
        position: 'fixed',
        transition: theme.transitions.create('-webkit-transform', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        width: drawerWidth,
        height: '100%'
    },
    drawerPaperShift: {
        transform: 'translate3d(0, 0, 0) !important',
    },
    flex: {
        flex: 1
    },
    flexDisp: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 12
    },
    flexBetween: {
        justifyContent: 'space-between'
    },
    globalLoader: {
        zIndex: 9999,
        width: '100%',
        position: 'fixed'
    },
    hide: {
        display: 'none'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    noNotifications: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100
    },
    notificationLayer: {
        padding: '20px 24px',
        cursor: 'pointer',
        marginTop: 24,
        outline: "inherit",
        margin: '5px 0px'
    },
    notificationBadge: {
        marginTop: 13,
        marginRight: 13
    },
    notificationsMenu: {
        backgroundColor: theme.palette.background.default,
        width: 400,
        padding: '8px 24px',
        marginTop: 49,
        overflow: 'visible',
        marginRight: 10,
        '&:before': {
            position: 'absolute',
            right: 119,
            top: -16,
            borderColor: `transparent transparent ${theme.palette.background.default}`,
            border: '8px solid',
            height: 0,
            width: 0,
            content: '" "'
        }
    },
    searchComponent: {
        marginLeft: 10,
        width: 400,
        '& .Select-placeholder, .Select--single .Select-value': {
            color: theme.palette.primary.contrastText,
        }
    },
    preScreen: {
        position: 'fixed',
        top: 5,
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 9999
    },
    preScreenImg: {
        maxHeight: 350,
        maxWidth: 400,
        width: '30%'
    },
    topInput: {
        '&:before': {
            borderBottomColor: theme.palette.primary.contrastText
        },
        '&:after': {
            borderBottomColor: theme.palette.primary.contrastText
        },
        '&:hover:before': {
            borderBottomColor: `${theme.palette.primary.contrastText} !important`
        }
    },
    topMenu: {
        width: 300,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        '&:focus': {
            outline: 'inherit'
        }
    },
    topMenuBottom: {
        width: 300,
        paddingTop: 7,
        paddingLeft: 16,
        paddingRight: 16,
        '&:focus': {
            outline: 'inherit'
        }
    },
    white: {
        "& input": {
            color: "white !important"
        }
    }
});

export default withRouter(compose(
    withStyles(styles, {
        name: 'NavMenu'
    }),
    connect(
        state => state.navMenu,
        dispatch => bindActionCreators(actionCreators, dispatch)
    )
)(NavMenu));