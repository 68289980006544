import HttpUtil from '../util/HttpUtil';
import { showGlobalLoaderType, hideGlobalLoaderType, showSnackBarType } from './NavMenu';

const startAddUserType = 'START_ADD_USER';
const changePasswordType = 'CHANGE_PASSWORD_TYPE';
export const fetchAgentTypesType = "FETCH_AGENT_TYPES";
export const addUserType = 'ADD_USER';
export const errorType = 'ERROR_ADD_USER_TYPE';
export const editUserType = 'EDIT_USER';
export const editUserActiveType = 'EDIT_ACTIVE_USER';
export const deleteUserType = 'DELETE_USER';
const resetErrorType = "RESET_ERROR";
const stopSavingType = "STOP_USER_SAVING";

const initialState = {
    isSaving: false,
    user: null,
    agentTypes: null,
    error: null
};

export const actionCreators = {
    fetchAgentTypes: () => async (dispatch, getState) => {
        HttpUtil.fetchGet('api/AgentTypes', null, true,
            data => {
                dispatch({ type: fetchAgentTypesType, data })
            }
        );
    },
    startLoadUser: () => ({ type: showGlobalLoaderType }),
    stopLoadUser: () => ({ type: hideGlobalLoaderType }),
    showSnackBar: (message) => ({ type: showSnackBarType, message }),
    resetError: () => ({ type: resetErrorType }),
    changePassword: (fields) => async (dispatch, getState) => {
        dispatch({
            type: startAddUserType
        });
        dispatch({
            type: showGlobalLoaderType
        });
        HttpUtil.fetchPost('api/Login/ChangePassword', fields, true,
            data => {
                if (data.reason) {
                    var errors = data.reason.split(";");
                    var rusErrors = [];
                    var isPassword = true;
                    errors.forEach(err => {
                        switch (err) {
                            case "Passwords must be at least 6 characters.":
                                rusErrors.push("Минимальная длина пароля 6 символов");
                                break;
                            case "Passwords must have at least one non alphanumeric character.":
                                rusErrors.push("Пароль должен содержать один непрописной символ (!,*,? ...)");
                                break;
                            case "Passwords must have at least one digit ('0'-'9').":
                                rusErrors.push("Пароль должен содержать хотя бы одну цифру");
                                break;
                            case "Passwords must have at least one lowercase ('a'-'z').":
                                rusErrors.push("Пароль должен содержать хотя бы одну строчную букву");
                                break;
                            case "Passwords must have at least one uppercase ('A'-'Z').":
                                rusErrors.push("Пароль должен содержать хотя бы одну прописную букву");
                                break;
                            default:
                                rusErrors.push("Ошибка сервера: " + err);
                                isPassword = false;
                        }
                    });

                    if (isPassword) {
                        dispatch({
                            type: errorType,
                            error: rusErrors.join("; ")
                        });
                    } else {
                        dispatch({
                            type: errorType,
                            error: rusErrors.join("; ")
                        });
                    }

                } else {
                    dispatch({
                        type: changePasswordType
                    });
                    dispatch({
                        type: showSnackBarType,
                        message: "Пароль успешно изменён" +
                            (data.error ? ". Произошла ошибка отправки почтового уведомления: " + data.error : "")
                    });
                }

                dispatch({
                    type: hideGlobalLoaderType
                });
            }
        )
    },
    addUser: (fields, role, sendMail, callback) => async (dispatch, getState) => {
        dispatch({
            type: startAddUserType
        });
        dispatch({
            type: showGlobalLoaderType
        });
        HttpUtil.fetchEdit('api/Users?role=' + role + "&password=" + encodeURIComponent(fields.password) + "&sendMail=" + sendMail, JSON.stringify(fields), "POST", false, 
            data => {
                if (data.reason) {
                    var errors = data.reason.split(";");
                    var rusErrors = [];
                    var isPassword = true;
                    if (data.reason.indexOf("already taken") > -1) {
                        rusErrors.push("Пользователь с данным E-mail уже есть в системе");
                        isPassword = false;
                    } else {
                        errors.forEach(err => {
                            switch (err) {
                                case "Passwords must be at least 6 characters.":
                                    rusErrors.push("Минимальная длина пароля 6 символов");
                                    break;
                                case "Passwords must have at least one non alphanumeric character.":
                                    rusErrors.push("Пароль должен содержать один непрописной символ (!,*,? ...)");
                                    break;
                                case "Passwords must have at least one digit ('0'-'9').":
                                    rusErrors.push("Пароль должен содержать хотя бы одну цифру");
                                    break;
                                case "Passwords must have at least one lowercase ('a'-'z').":
                                    rusErrors.push("Пароль должен содержать хотя бы одну строчную букву");
                                    break;
                                case "Passwords must have at least one uppercase ('A'-'Z').":
                                    rusErrors.push("Пароль должен содержать хотя бы одну прописную букву");
                                    break;
                                default:
                                    rusErrors.push("Ошибка сервера: " + err);
                                    isPassword = false;
                            }
                        });
                    }
                    if (isPassword) {
                        dispatch({
                            type: errorType,
                            error: rusErrors.join("; ")
                        });
                    } else {
                        dispatch({
                            type: changePasswordType,
                            error: rusErrors.join("; ")
                        });
                        dispatch({
                            type: showSnackBarType,
                            message: rusErrors.join("; ")
                        });
                    }
                } else {
                    dispatch({
                        type: addUserType,
                        fields: Object.assign({}, fields, {
                            id: data.id,
                            role,
                            code: data.code,
                            error: data.error
                        })
                    });

                    dispatch({
                        type: showSnackBarType,
                        message: "Новый пользователь успешно добавлен!" + (data.error ? " Произошла ошибка при отправлениии письма на почту: " + data.error : "")
                    });

                    callback("/users");
                }

                dispatch({
                    type: hideGlobalLoaderType
                });
            }
        )
    },
    editUser: (fields, role, callback, blocking) => async (dispatch, getState) => {
        dispatch({
            type: startAddUserType
        });
        dispatch({
            type: showGlobalLoaderType
        });
        HttpUtil.fetchEdit('api/Users/' + fields.id + "?role=" + role, JSON.stringify(fields), "PUT", false, 
            data => {
                if (data.reason) {
                    dispatch({
                        type: changePasswordType,
                        error: "Ошибка сервера: " + data.reason
                    });

                    dispatch({
                        type: showSnackBarType,
                        message: "Ошибка сервера: " + data.reason
                    });

                    dispatch({
                        type: hideGlobalLoaderType
                    });

                    return;
                } 

                dispatch({
                    type: blocking ? editUserActiveType : editUserType,
                    fields: fields,
                    role
                });

                dispatch({
                    type: hideGlobalLoaderType
                });

                let message = !blocking ?
                    "Пользователь успешно отредактирован!" :
                    `Доступ к порталу у данной учетной записи ${blocking === "true" || blocking === true ? "ограничен" : "восстановлен"}!`;

                dispatch({
                    type: showSnackBarType,
                    message
                });

                callback("/users");
            }
        )
    },
    deleteUser: (id, callback) => async (dispatch, getState) => {
        dispatch({
            type: startAddUserType
        });
        dispatch({
            type: showGlobalLoaderType
        });

        HttpUtil.fetchEdit("api/Users/" + id, {}, "DELETE", false, 
            data => {
                dispatch({
                    type: hideGlobalLoaderType
                });

                if (data.error) {
                    alert(data.error);
                    dispatch({
                        type: stopSavingType
                    });
                    return;
                }

                dispatch({
                    type: deleteUserType,
                    id
                });

                dispatch({
                    type: showSnackBarType,
                    message: "Пользователь успешно удален!"
                });

                callback("/users");
            }
        )
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === fetchAgentTypesType) {
        return {
            ...state,
            agentTypes: action.data
        }
    }

    if (action.type === changePasswordType) {
        return {
            ...state,
            isSaving: false
        }
    }

    if (action.type === startAddUserType) {
        return {
            ...state,
            isSaving: true
        }
    }

    if (action.type === addUserType) {
        return {
            ...state,
            isSaving: false
        }
    }

    if (action.type === resetErrorType) {
        return {
            ...state,
            error: null
        }
    }

    if (action.type === errorType) {
        return {
            ...state,
            isSaving: false,
            error: action.error
        }
    }

    if (action.type === deleteUserType) {
        return {
            ...state,
            isSaving: false,
            error: null
        }
    }

    if (action.type === stopSavingType) {
        return {
            ...state,
            isSaving: false
        }
    }

    if (action.type === resetErrorType) {
        return {
            ...state,
            isSaving: false,
            error: null
        }
    }

    if (action.type === editUserActiveType || action.type === editUserType) {
        return {
            ...state,
            isSaving: false
        }
    }

    return state;
};
