import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';

var initialState = {};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType })
};

export const reducer = (state, action) => {
    state = state || initialState;
    return state;
};
