import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import asyncComponent from './components/AsyncComponent';

import { GetRole } from "./const/common";
import { ADMIN_ROLE, SUPER_ADMIN_ROLE, OWNER_ROLE, MARKETOLOGIST_ROLE, MANAGER_ROLE } from './const/roles';

const Clients = asyncComponent(() => import("./components/Clients"));
const UserEdit = asyncComponent(() => import("./components/UserEdit"));
const Users = asyncComponent(() => import("./components/Users"));
const Login = asyncComponent(() => import("./components/Login"));
const Settings = asyncComponent(() => import("./components/Settings"));
const ClientEdit = asyncComponent(() => import("./components/ClientEdit"));
const ClientReg = asyncComponent(() => import("./components/ClientReg"));
const ClientRegComplete = asyncComponent(() => import("./components/ClientRegComplete"));
const AgentRegComplete = asyncComponent(() => import("./components/AgentRegComplete"));
const ClientView = asyncComponent(() => import("./components/ClientView"));
const ClientImport = asyncComponent(() => import("./components/ClientImport"));
const UserView = asyncComponent(() => import("./components/UserView"));
const PhoneVerify = asyncComponent(() => import("./components/PhoneVerify"));
const AccessForbidden = asyncComponent(() => import("./components/AccessForbidden"));
const Analytics = asyncComponent(() => import("./components/Analytics"));
const AgentClients = asyncComponent(() => import("./components/AgentClients"));

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={
                GetRole() === OWNER_ROLE.value || GetRole() === MARKETOLOGIST_ROLE.value || GetRole() === MANAGER_ROLE.value ? Clients :
                    GetRole() === ADMIN_ROLE.value || GetRole() === SUPER_ADMIN_ROLE.value ? Users :
                        PhoneVerify
            }
            />
            <Route path='/users' component={Users} />
            <Route path='/analytics' component={Analytics} />
            <Route path='/registration/:id?' component={ClientReg} />
            <Route path='/complete' component={ClientRegComplete} />
            <Route path='/success' component={AgentRegComplete} />
            <Route path='/agents' component={Users} />
            <Route path='/viewuser/:id' component={UserView} />
            <Route path='/user/:type/:id?' component={UserEdit} />
            <Route path='/login' component={Login} />
            <Route path='/settings' component={Settings} />
            <Route path='/viewclient/:id' component={ClientView} />
            <Route path='/viewimport/:id' component={ClientImport} />
            <Route path='/client/:type/:id?' component={ClientEdit} />
            <Route path='/clients/:type' component={Clients} />
            <Route path='/access_forbidden' component={AccessForbidden} />
            <Route path='/my_clients' component={AgentClients} />
        </Switch>
    </Layout>
);
