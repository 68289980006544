import HttpUtil from "../util/HttpUtil";
import { editUserType, addUserType, editUserActiveType, deleteUserType, fetchAgentTypesType, actionCreators as UserEdit } from "./UserEdit";
import * as Roles from '../const/roles';
import { showGlobalLoaderType, hideGlobalLoaderType } from "./NavMenu";
import { GetRole } from "../const/common";

const fetchUsersDataType = "FETCH_USERS_DATA";
export const clearUsersGridType = "CLEAR_USERS_DATA";

const initialState = {
    users: null,
    agentTypes: null,
    isLoading: true
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    fetch: () => async (dispatch, getState) => {
        dispatch({ type: showGlobalLoaderType });
        HttpUtil.fetchGet("api/Users", null, true,
            data => {
                dispatch({ type: fetchUsersDataType, users: data });
                dispatch({ type: hideGlobalLoaderType });
            }
        );
    },
    fetchAgentTypes: () => async (dispatch, getState) => {
        dispatch(UserEdit.fetchAgentTypes());
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === clearUsersGridType) {
        return {
            ...state,
            users: null,
            isLoading: true
        }
    }

    if (action.type === fetchAgentTypesType) {
        return {
            ...state,
            agentTypes: action.data
        }
    }

    if (action.type === fetchUsersDataType) {
        return {
            ...state,
            users: action.users,
            isLoading: false
        };
    }

    if (action.type === addUserType) {
        if (state.users === null) {
            return state;
        }
        var user = action.fields;
        var k = Object.keys(Roles).find(k => Roles[k].value === action.fields.role);
        var role = Roles[k].label;

        user.createDate = new Date().toISOString();
        user.status = "Активен";
        user.roles = [
            role
        ]
        user.surname = action.fields.role === Roles.AGENT_ROLE.value && GetRole() !== Roles.MANAGER_ROLE.value ? action.fields.name : action.fields.surname;

        return {
            ...state,
            users: [...state.users, user]
        };
    }

    if (action.type === editUserActiveType || action.type === editUserType) {
        if (state.users === null) {
            return state;
        }
        var k = Object.keys(Roles).find(k => Roles[k].value === action.role);
        var role = Roles[k].label;
        var agentRole = Roles.AGENT_ROLE.value;

        let users = state.users.map(n => {
            if (n.id === action.fields.id) {
                return Object.assign({}, n, action.fields, {
                    roles: [role],
                    surname: action.role === agentRole && GetRole() !== Roles.MANAGER_ROLE.value ? action.fields.name : action.fields.surname,
                    status: action.fields.blocked === "false" || action.fields.blocked === false ? "Активен" : "Заблокирован" 
                });
            }
            return n;
        });

        return {
            ...state,
            users
        };
    }

    if (action.type === deleteUserType) {
        let users = [...state.users];
        users = users.filter(u => u.id !== action.id);

        return {
            ...state,
            users
        };
    }

    return state;
 };
