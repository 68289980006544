import { showGlobalLoaderType, hideGlobalLoaderType, hidePreScreenType } from './NavMenu';

const initialState = {
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    hideGlobalLoader: () => ({ type: hidePreScreenType }),
};

export const reducer = (state, action) => {
    state = state || initialState;
    return state;
};
