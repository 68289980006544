export const ADMIN_ROLE = {
    label: "Администратор",
    value: "ADMIN"
};
export const SUPER_ADMIN_ROLE = {
    label: "Суперадмин",
    value: "SUPER_ADMIN"
};

export const AGENT_ROLE = {
    label: "Агент",
    value: "AGENT"
};

export const MANAGER_ROLE = {
    label: "Менеджер по корпоративным клиентам",
    value: "MANAGER"
};

export const OWNER_ROLE = {
    label: "Собственник бизнеса",
    value: "OWNER"
};

export const EMPLOYER_ROLE = {
    label: "Сотрудник точки продаж",
    value: "EMPLOYER"
};

export const MARKETOLOGIST_ROLE = {
    label: "Маркетолог",
    value: "MARKETOLOGIST"
};