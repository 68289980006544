import React, { Component } from 'react';
import { GetRole } from '../const/common';
import NavMenu from './NavMenu';

export default class Layout extends Component {
    render() {
        return <NavMenu>
            {this.props.children}
        </NavMenu>;
    }
}