import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';
import HttpUtil from '../util/HttpUtil';

const fetchFiltersType = "FETCH_CLIENT_FILTERS";

const initialState = {
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType })
};

export const reducer = (state, action) => {
    state = state || initialState;
    return state;
};
