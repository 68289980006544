import Cookies from "js-cookie";
import HttpUtil from "../util/HttpUtil";
import { GetRole } from "../const/common";
import { AGENT_ROLE, EMPLOYER_ROLE } from "../const/roles";
import { clearUsersGridType } from "./Users";

const hideDrawerType = "HIDE_DRAWER";
const openDrawerType = "OPEN_DRAWER";

export const hidePreScreenType = "HIDE_PRE_SCREEN";
const logoutType = "LOGOUT";

export const checkLoginType = "CHECK_LOGIN";
export const showGlobalLoaderType = "SHOW_GLOBAL_LOADER";
export const showSnackBarType = "SHOW_SNACK_BAR";
const hideSnackBarType = "HIDE_SNACK_BAR";
export const hideGlobalLoaderType = "HIDE_GLOBAL_LOADER";
//const receiveLoginAction = "RECEIVE_LOGIN";
//const completeTeachingType = "COMPLETE_TEACHING";
export const fetchNotificationsType = "FETCH_NOTIFICATIONS";
export const getDaDataTokenType = "GET_DADATA_TOKEN";

const initialState = {
    showLoginButton: true,
    drawerOpen: window.localStorage.getItem("drawerOpen") === "true" && GetRole() !== AGENT_ROLE.value && GetRole() !== null,
    searchComponent: null,
    user: null,
    globalLoading: true,
    showPreScreen: true,
    snackBarMessage: "",
    //notifications: []
};

export const actionCreators = {
    readNotification: (id) => async (dispatch, getState) => {/*
        HttpUtil.fetchGet("api/DocumentTypes/GetDocumentTypes", { isForm: true }, true,
            data => {
                dispatch({ type: fetchDocumentTypesDataType, documentTypes: data.documentTypes });
            }
        );*/
    },
    showGlobalLoader: () => ({ type: showGlobalLoaderType }),
    hideGlobalLoader: () => ({ type: hideGlobalLoaderType }),
    hideSnackBar: () => ({ type: hideSnackBarType }),
    /*completeTeaching: () => async (dispatch, getState) => {
        dispatch({ type: completeTeachingType });
        HttpUtil.fetchGet("/api/Users/CompleteTeaching", null, true);
    },*/
    checkLogin: () => async (dispatch, getState) => {
        var token = Cookies.get("accessToken");
        if (token) {
            HttpUtil.fetchGet("api/Login/CheckToken", null, true,
                data => {
                    if (data.accessToken !== undefined) {
                        Cookies.set("accessToken", data.accessToken, { expires: 7 });
                    }

                    dispatch({
                        type: checkLoginType,
                        isLogin: data.errorCode === 200,
                        user: data.user
                    });
                    dispatch({ type: hidePreScreenType });
                },
                err => {
                    dispatch({ type: checkLoginType, isLogin: false });
                    dispatch({ type: hidePreScreenType });
                }
            );
        } else if (typeof document !== "undefined") {
            dispatch({ type: checkLoginType, isLogin: false });
        }

        HttpUtil.fetchGet("api/Settings/GetDaDataApi", null, true,
            data => {
                dispatch({ type: getDaDataTokenType, token: data.token });
            }
        );
    },
    fetch: () => async (dispatch, getState) => {
        /*HttpUtil.fetchGet("api/Notifications/GetNotifications", null, true,
            data => {
                dispatch({ type: fetchNotificationsType, notifications: data.notifications });
            }
        );*/
    },
    logout: () => (dispatch, getState) => {
        Cookies.remove("accessToken");
        dispatch({ type: logoutType, isLogin: false });
        dispatch({ type: clearUsersGridType });
    },
    hideDrawer: () => ({ type: hideDrawerType }),
    openDrawer: () => ({ type: openDrawerType })
};

export const reducer = (state, action) => {
    state = state || initialState;

    /*if (action.type === completeTeachingType) {
        let user = { ...state.user, teachingComplete: true };
        return {
            ...state,
            user: user
        }
    }
    if (action.type === fetchNotificationsType) {
        return {
            ...state,
            notifications: action.notifications
        }
    }*/
    if (action.type === showGlobalLoaderType) {
        return {
            ...state,
            globalLoading: true
        }
    }

    if (action.type === hideGlobalLoaderType) {
        return {
            ...state,
            globalLoading: false
        }
    }

    if (action.type === hidePreScreenType) {
        return {
            ...state,
            showPreScreen: false,
            globalLoading: false
        }
    }

    if (action.type === checkLoginType) {
        return {
            ...state,
            showLoginButton: action.isLogin,
            drawerOpen: GetRole() !== AGENT_ROLE.value && state.drawerOpen,
            user: action.user
        }
    }

    if (action.type === logoutType) {
        return {
            ...state,
            drawerOpen: false,
            showLoginButton: false,
            user: null
        }
    }

    if (action.type === openDrawerType) {
        window.localStorage.setItem("drawerOpen", true);
        return {
            ...state,
            drawerOpen: true
        }
    }

    if (action.type === hideDrawerType) {
        window.localStorage.setItem("drawerOpen", false);
        return {
            ...state,
            drawerOpen: false
        }
    }

    if (action.type === showSnackBarType) {
        return {
            ...state,
            snackBarMessage: action.message
        }
    }

    if (action.type === hideSnackBarType) {
        return {
            ...state,
            snackBarMessage: ""
        }
    }


    return state;
};
