import { showGlobalLoaderType, hideGlobalLoaderType } from './NavMenu';
import {  fetchAgentTypesType, actionCreators as UserEdit } from './UserEdit';

const initialState = {
    agentTypes: null
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    fetchAgentTypes: () => UserEdit.fetchAgentTypes()
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === fetchAgentTypesType) {
        return {
            ...state,
            agentTypes: action.data
        }
    }

    return state;
};
