import Cookies from "js-cookie";

import HttpUtil from "../util/HttpUtil";
import { hidePreScreenType, showGlobalLoaderType, hideGlobalLoaderType, checkLoginType } from "./NavMenu";

const requestLoginType = "REQUEST_LOGIN";
const receiveLoginType = "RECEIVE_LOGIN";
const focusLoginType = "FOCUS_LOGIN";
const focusPasswordType = "FOCUS_PASSWORD";
const closeDialogType = "CLOSE_DIALOG";
const passwordChangedType = "PASSWORD_CHANGED"

const initialState = {
    isLoading: false,
    openRestoreDia: false
};

export const actionCreators = {
    showLoader: () => ({ type: showGlobalLoaderType }),
    stopLoader: () => ({ type: hideGlobalLoaderType }),
    hideGlobalLoader: () => ({ type: hidePreScreenType }),
    requestLogin: (email, password) => async (dispatch, getState) => {
        if (email == null || password == null) {
            dispatch({
                type: receiveLoginType,
                error: "Логин и пароль являются обязательными полями"
            });
            return;
        }

        if (email.trim() === "") {
            dispatch({
                type: receiveLoginType,
                loginError: "Введите логин"
            });
            return;
        }
        
        if (password === "") {
            dispatch({
                type: receiveLoginType,
                passwordError: "Введите пароль"
            });
            return;
        }

        dispatch({ type: requestLoginType });
        dispatch({ type: showGlobalLoaderType });

        HttpUtil.fetchGet("api/Login/SignIn",
            {
                email,
                password
            },
            false,
            data => {
                dispatch({ type: hideGlobalLoaderType });
                if (data.errorCode === 200) {
                    Cookies.set("accessToken", data.accessToken, { expires: 7 });
                    dispatch({
                        type: checkLoginType,
                        user: data.user,
                        isLogin: true
                    });
                    /*HttpUtil.fetchGet("api/Notifications/GetNotifications", null, true,
                        notifData => {
                            dispatch({ type: fetchNotificationsType, notifications: notifData.result.notifications });
                        }
                    );*/
                }
                dispatch({
                    type: receiveLoginType,
                    error: data.errorCode === 200 ? undefined : data.error
                });
            },
            err => {
                dispatch({
                    type: receiveLoginType,
                    error: err.message
                })
            }
        )
    },
    focusLogin: () => ({ type: focusLoginType }),
    focusPassword: () => ({ type: focusPasswordType }),
    closeDialog: () => ({ type: closeDialogType }),
    restorePass: (email, callback) => async (dispatch, getState) => {
        if (email == null || email.trim() === "") {
            dispatch({
                type: receiveLoginType,
                error: "Введите почту для восстановления пароля"
            });
            return;
        }

        dispatch({ type: requestLoginType });
        dispatch({ type: showGlobalLoaderType });

        HttpUtil.fetchGet("api/Login/Restore",
            {
                email
            },
            false,
            data => {
                dispatch({ type: hideGlobalLoaderType });
                if (data.error) {
                    Cookies.set("accessToken", data.accessToken, { expires: 7 });
                    dispatch({
                        type: receiveLoginType,
                        error: "Произошла ошибка при сбросе пароля, обратитесь к администратору системы"
                    });
                }
                dispatch({ type: passwordChangedType });
                callback();
            },
            err => {
                dispatch({
                    type: receiveLoginType,
                    error: err.message
                })
            }
        )
    },
};


export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case closeDialogType:
            return Object.assign({}, state, {
                error: undefined
            });
        case focusLoginType:
            return Object.assign({}, state, {
                loginError: undefined
            });
        case focusPasswordType:
            return Object.assign({}, state, {
                passwordError: undefined
            });
        case requestLoginType:
            return Object.assign({}, state, {
                isLoading: true
            });
        case receiveLoginType:
            return Object.assign({}, state, {
                error: action.error,
                loginError: action.loginError,
                passwordError: action.passwordError,
                isLoading: false
            });
        default:
            return state;
    }
};
