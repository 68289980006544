import Cookies from "js-cookie";


let HttpUtil = {
    daData: (token, type, text, successCallback, errorCallback) => {
        var options = {
            method: "POST",
            body: `${JSON.stringify({ query: text })}`,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            }
        };
        
        fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/" + type, options)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                console.log(responseObj);
                if (responseObj !== undefined) {
                    successCallback(responseObj);
                }
            })
            .catch(error => {
                errorCallback(error);
            });
    },
    fetchGet: (url, params, token, successCallback, failCallback) => {
        if (params) {
            url += "?";// "?key=" + KEY;
            var paramsBody = Object.keys(params)
                .reduce((a, k) => {
                    if (Array.isArray(params[k]))
                        params[k].forEach((e) => {
                            a.push(k + '=' + encodeURIComponent(e));
                        });
                    else
                        a.push(k + '=' + encodeURIComponent(params[k].toString()));
                    return a;
                    return a;
                }, [])
                .join('&');
            url += /*"&" + */paramsBody;
        }

        var options = {
            method: 'GET'
        };

        if (token) {
            options.headers = {
                'Authorization': `Bearer ${Cookies.get('accessToken')}`
            }
        }

        fetch(url, options)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    failCallback({
                        status: response.status,
                        statusText: response.statusText
                    });
                }
            })
            .then(responseObj => {
                console.log(responseObj);
                if (responseObj !== undefined) {
                    successCallback(responseObj);
                }
            })
            .catch(error => {
                if (failCallback)
                    failCallback(error);
            });
    },

    fetchFile: (url, successCallback, failCallback) => {
        var options = {
            method: 'GET'
        };

        options.headers = {
            'Authorization': `Bearer ${Cookies.get('accessToken')}`
        }

        fetch(url, options)
            .then(response => response.blob())
            .then(responseObj => {
                console.log(responseObj);
                if (responseObj !== undefined) {
                    successCallback(responseObj);
                }
            })
            .catch(error => {
                if (failCallback)
                    failCallback(error);
            });
    },

    fetchPostFile: (url, params, token, successCallback, failCallback) => {
        let data = new FormData();

        data.append('bytes', params.file);
        for (var key in params) {
            if (key !== 'file') {
                data.append(key, params[key])
            }
        };

        const options = {
            method: 'POST',
            headers: {
                //'Accept': 'application/json',
                //'Content-Type': 'multipart/form-data',
                //'Content-Language': React.NativeModules.RNI18n.locale,
                'Authorization': `Bearer ${Cookies.get('accessToken')}`
            },
            body: data,
        }

        fetch(url, options)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                successCallback(responseObj);
            })
            .catch(error => {
                if (failCallback)
                    failCallback(error);
            });
    },

    fetchPost: (url, params, token, successCallback, failCallback) => {
        if (typeof params !== 'string')
            url += "?";// "?key=" + KEY;

        var options = {
            method: 'POST'
        };

        if (typeof params !== 'string') {
            var paramsBodyArr = Object.keys(params)
                .reduce((a, k) => {
                    if (k !== 'picture' && k !== 'text' && params[k] != null) {
                        a.push(k + "=" + encodeURIComponent(params[k].toString()));
                    }
                    return a;
                }, []);
            var paramsBody = paramsBodyArr.join('&');
            url += /*"&" + */paramsBody;
        } else {
            options.body = `'${params}'`
        }

        if (token) {
            options.headers = {
                'Authorization': `Bearer ${Cookies.get('accessToken')}`,
                'Content-Type': 'application/json'
            }
        }
        else {
            options.headers = {
                'Content-Type': 'application/json'
            }
        }

        fetch(url, options)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                successCallback(responseObj);
            })
            .catch(error => {
                if (failCallback)
                    failCallback(error);
            });
    },

    fetchEdit: (url, params, method, stringify, successCallback, failCallback) => {
        var options = {
            method: method,
            body: stringify ? `'${params}'` : `${params}`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('accessToken')}`,
                'Content-Type': 'application/json'
            }
        };

        fetch(url, options)
            .then(response => {
                return response.json();
            })
            .then(responseObj => {
                successCallback(responseObj);
            })
            .catch(error => {
                if (failCallback)
                    failCallback(error);
            });
    },
}

export default HttpUtil;
